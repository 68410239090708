<template>
  <div class="contact">
    <Card ref="card" digit="04">
      <div class="contact__content">
        <TickerHeadline>CONTACT</TickerHeadline>

        <div class="contact__container">
          <div class="contact__column">
            <p>
              You can contact me through social networks, use this form or email me directly at <a href="mailto:hello@marcstorch.com">hello@marcstorch.com</a>.
              Thank you!
            </p>

            <form ref="form" @submit="onFormSubmit">
              <input id="hp" class="hidden" />
              <InputField
                ref="fname"
                label="Full Name *"
                id="fullname"
                type="text"
                @input="errorList.fullname = false"
                :class="{ error: errorList.fullname }"
              />
              <InputField
                ref="email"
                label="E-Mail *"
                id="email"
                type="email"
                :class="{ error: errorList.email }"
              />
              <InputField
                ref="msg"
                label="Message *"
                id="msg"
                type="text"
                :class="{ error: errorList.msg }"
              />
              <span class="contact__mandatory-note">* required</span>
              <p class="contact__success-note" ref="success" v-if="success">Thank You! I will answer as soon as possible.</p>
              <p class="contact__success-note" ref="error" v-if="error">Ooops. Something went wrong. Please try again later.</p>
              <div class="contact__button-container">

              <Button @click.native="submit" :disabled="blocked">SEND</Button>
              </div>
            </form>

            <h4>NETWORKS</h4>
            <div class="contact__social">
              <a href="https://www.linkedin.com/in/marc-storch/" target="_blank"
                ><inline-svg :src="require('@/assets/svg/linkedin.svg')"
              /></a>
              <a href="https://www.xing.com/profile/Marc_Storch2/cv" target="_blank"
                ><inline-svg :src="require('@/assets/svg/xing.svg')"
              /></a>
              <a href="https://www.instagram.com/marc_strolch/" target="_blank"
                ><inline-svg :src="require('@/assets/svg/insta.svg')"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import Card from '../components/Card'
import InputField from '../components/InputField'
import Button from '../components/Button'
import TickerHeadline from '../components/TickerHeadline'
import DataMixin from '../mixins/DataMixin'
import AppearMixin from '../mixins/AppearMixin'

export default {
  name: 'Projects',
  mixins: [AppearMixin, DataMixin],
  components: {
    Card,
    InputField,
    TickerHeadline,
    Button
  },
  data: function () {
    return {
      error: false,
      success: false,
      blocked: false,
      errorList: {
        fullname: false,
        email: false,
        msg: false
      },
      model: {
        fullname: '123',
        email: 'mail@mailk.com',
        msg: 'test123'
      }
    }
  },
  props: {
    project: undefined
  },
  metaInfo: {
    title: 'MARCSTORCH.COM | CONTACT'
  },
  watch: {
    success () {
      this.$nextTick(() => {
        gsap.to(this.$refs.success, { height: 'auto', opacity: 1, y: 0 })
      })
    },
    error () {
      if (this.error) {
        this.$nextTick(() => {
          gsap.to(this.$refs.error, { ease: 'Expo.easeOut', height: 'auto', y: 0, duration: 0.7 })
          gsap.to(this.$refs.error, { opacity: 1, duration: 0.25 })

          gsap.to(this.$refs.error, { delay: 5, height: 0, y: 10, duration: 0.25 })
          gsap.to(this.$refs.error, { delay: 5, opacity: 0, duration: 0.25 }).then(() => {
            this.error = false
            this.blocked = false
          })
        })
      }
    }
  },
  methods: {
    submit () {
      if (this.blocked) return
      const email = document.getElementById('email').value
      const fullname = document.getElementById('fullname').value
      const msg = document.getElementById('msg').value.split('\n').join('<br>')

      let error = false

      if (fullname.length < 5) {
        error = true
        this.errorList.fullname = true
      } else {
        this.errorList.fullname = false
      }

      if (msg.length < 5) {
        error = true
        this.errorList.msg = true
      } else {
        this.errorList.msg = false
      }

      if (email.length < 2) {
        error = true
        this.errorList.email = true
      } else {
        const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (re.test(String(email).toLowerCase())) {
          this.errorList.email = false
        } else {
          error = true
          this.errorList.email = true
        }
      }
      // marcstorch.com/test/mail.php

      document.getElementById('hp').value = ' x '
      if (error) return

      this.blocked = true

      this.$http({
        method: 'post',
        url: '//www.marcstorch.com/php/mail.php',
        data: {
          form: {
            email: email,
            msg: msg,
            fullname: fullname,
            hp: document.getElementById('hp').value
          }
        }
      })
        .then((response) => {
          // handle success
          // console.log(response)

          this.blocked = true
          this.success = true
        })
        .catch((response) => {
          // handle error
          // console.log(response)
          this.error = true
        })
    },
    onFormSubmit () {
      // console.log('WHHHE')
    }
  },
  mounted () {
    // this.fetchData('contact')
  }
}
</script>
