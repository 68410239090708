<template>
  <div class="input">
    <label for="fname">{{label}}</label>
    <textarea type="text" :id="id" :name="id" v-if="id === 'msg'" @input="$emit('input')" />
    <input type="text" :id="id" :name="id" @input="$emit('input')" v-else/>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    label: String,
    id: String,
    type: String
  },
  validate () {

  }
}
</script>
